<template>
  <div class="coord-details" :class="color">
    <h3>{{ title }}</h3>
    <ul>
      <li v-for="(item, index) in items" :key="index">
        <span>{{ item }}</span>
      </li>
    </ul>
    <div class="bullet-point-button-container" v-if="isButton">
      <button class="bullet-point-button" @click="clickButton">
        {{ buttonName }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "BulletPointBox",
  props: {
    title: String,
    items: Array,
    color: String,
    isButton: {
      type: Boolean,
      default: false,
      description: "Show button"
    },
    buttonName: {
      type: String
    }
  },
  methods: {
    clickButton() {
      this.$emit("activateClick");
    }
  }
};
</script>

<style lang="less" scoped>
.coord-details {
  margin-bottom: 3em;
  background-color: var(--secondary-blue);
  border: 2px solid var(--primary-blue);
  border-radius: 8px;
  box-shadow: 1px 0 10px 1px #0f0f0f8f, inset 0 1px 1px #0f0f0f8f;

  &.green {
    background-color: var(--secondary-green);
    border: 2px solid var(--primary-green);
  }

  h3 {
    margin: 12px 20px;
    color: white;
  }

  ul {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;

    li {
      list-style-type: none;
      margin-left: 16px;
      margin-bottom: 16px;
      color: #ffffff;
    }
  }
  .bullet-point-button-container {
    width: 100%;
    text-align: center;
    .bullet-point-button {
      margin-bottom: 12px;
      background: var(--primary-green);
      border: 0px solid;
      border-radius: 5px;
      box-shadow: 0px 0px 10px -2px #cfb595;
      color: #ffffff;
      cursor: pointer;
      padding: 10px 16px;
      font-weight: 600;
    }
  }
}
</style>
