<template>
  <div class="checkout-timer">
    <div class="timer-header" v-if="!checkoutPage">Tickets expire in</div>
    <div class="timer">{{ minutes }}:{{ seconds }}</div>

    <div class="text" v-if="checkoutPage">
      Your ticket reservations will be held for 10 minutes. If you do not
      complete your order within the slotted time, your tickets will be released
      back to the public.
    </div>
  </div>
</template>

<style lang="less" scoped>
.checkout-timer {
  display: flex;
  align-items: center;
  padding: 1em;
  color: white;
  font-weight: 500;
  text-transform: uppercase;
  background-color: #e60022;
  background-image: linear-gradient(#e60022, #b40621);
  border-radius: 10px;
  box-shadow: 0 1px 3px 1px #2222229d;

  .timer-header {
    margin-right: 7px;
    margin-top: 1px;
    font-size: 1.5em;
  }

  .timer {
    font-size: 2.55em;
    font-weight: 500;
  }
  // end timer

  .text {
    margin-left: 1em;
    font-size: 1.1em;
    line-height: 100%;
    word-spacing: 0.025em;
    letter-spacing: 0.05em;
  }
  // end text
}
@media only screen and (max-width: 730px) {
  .checkout-timer {
    padding: 8px;

    .timer-header {
      margin-right: 7px;
      margin-top: 1px;
      font-size: 12px;
      // font-size: 1.5em;
    }

    .timer {
      font-size: 1em;
      font-weight: 500;
    }
    .text {
      font-size: 10px;
    }
  }
}
</style>

<script>
export default {
  name: "checkout-timer",
  props: {
    date: Date,
    event: String,
    eventUrl: String,
    processing: Boolean,
    checkoutPage: Boolean
  },
  data() {
    return {
      myCookie: null,
      minutes: 0,
      seconds: 0
    };
  },

  mounted() {
    let cookie = document.cookie.split(";");
    let cookies = {};

    cookie.forEach(key => {
      let keyVal = key.trim().split("=");
      cookies[keyVal[0]] = keyVal[1];
    });
    let self = this;
    let deadline = new Date();
    // created cookie if it doesnt exist'
    if (!cookies[this.event]) {
      deadline.setMinutes(deadline.getMinutes() + 10);
      this.setCookie(this.event, deadline.getTime(), deadline.toUTCString());

      cookie = document.cookie.split(";");
      cookies = {};

      cookie.forEach(key => {
        let keyVal = key.trim().split("=");
        cookies[keyVal[0]] = keyVal[1];
      });
    }
    // else this.myCookie = cookies["checkoutTimer"];

    var countDown = function() {
      self.myCookie = cookies[self.event];
      self.setDeadline(deadline);
      let milliseconds = self.myCookie - Date.now();
      self.minutes = Math.floor(
        (milliseconds % (1000 * 60 * 60)) / (1000 * 60)
      );
      if (self.minutes < 10) self.minutes = "0" + self.minutes;
      self.seconds = Math.floor((milliseconds % (1000 * 60)) / 1000);
      if (self.seconds < 10) self.seconds = "0" + self.seconds;

      // checks if timer is up, reoutes page and resets deadline time in store, and destroys cookie

      if (self.seconds <= 0 && self.minutes <= 0) {
        document.cookie =
          self.event + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/";
        self.destroyDeadline();
        this.minutes = 0;
        this.seconds = 0;

        //for promocodes
        let discounts = JSON.parse(sessionStorage.getItem("discounts"));
        if (discounts == null) {
          discounts = [];
        } else {
          for (let x = 0; x < discounts.length; x++) {
            if (discounts[x].url == self.event) {
              discounts.splice(x, 1);
            }
          }
        }

        sessionStorage.setItem("discounts", JSON.stringify(discounts));
        self.$store.commit("updateDiscount", discounts);

        //for social discounts
        let SocialDiscounts = JSON.parse(
          sessionStorage.getItem("social-discounts")
        );
        if (SocialDiscounts == null) {
          SocialDiscounts = [];
        } else {
          for (let x = 0; x < SocialDiscounts.length; x++) {
            if (SocialDiscounts[x].url == self.event) {
              SocialDiscounts.splice(x, 1);
            }
          }
        }

        sessionStorage.setItem(
          "social-discounts",
          JSON.stringify(SocialDiscounts)
        );
        self.$store.commit("updateSocialDiscount", SocialDiscounts);

        // let orders = JSON.parse(sessionStorage.getItem('cart'))
        // for(let x = 0; x < orders.length; x++){
        //     if(orders[x].url == self.event){
        //         orders.splice(x, 1)
        //     }
        // }
        // sessionStorage.setItem('cart', JSON.stringify(orders))
        // self.$store.commit('updateCart', orders)

        // let saleDates = JSON.parse(sessionStorage.getItem('saleDates'))
        // for(let x = 0; x < saleDates.length; x++){
        //     console.log(saleDates[x])
        //     console.log(self.event)
        //     if(saleDates[x].eventUrl == self.event){
        //         saleDates.splice(x, 1)
        //     }
        // }
        // sessionStorage.setItem('saleDates', JSON.stringify(saleDates))
        // self.$store.commit('addDates', saleDates)

        // let dates = self.$store.state.eventDates;
        // let dateToRemove;

        // console.log(dates)
        // for(let x = 0; x < dates.length; x++){
        //     console.log('in loop')
        //     console.log(dates[x].eventUrl, self.event)
        //     if(dates[x].eventUrl == self.event){
        //         console.log('assigning date')
        //         dateToRemove = dates[x];
        //     }
        // }

        // console.log(dateToRemove)
        // self.$store.dispatch('removeDate', dateToRemove)

        let url = location.href.split("/");
        for (let x = 0; x < url.length; x++) {
          if (url[x] == self.event) {
            self.$router.push("/events");
          }
        }
      }
      // console.log('store' + self.deadlineTime);
      if (self.seconds >= 0 && self.minutes >= 0 && self.processing == false) {
        setTimeout(countDown, 1000);
      }
    };
    countDown();
  },
  methods: {
    setCookie(cname, cvalue, expiry) {
      document.cookie =
        cname + "=" + cvalue + "; expires=" + expiry + ";path=/";
    },
    setDeadline(time) {
      this.$store.commit("checkoutDeadline", time);
    },
    destroyDeadline() {
      this.$store.commit("destroyDeadline");

      if (this.$route.path.endsWith("/checkout")) {
        this.$router.push(`/${this.eventUrl}`);
      }
    }
  },
  computed: {
    deadlineTime() {
      return this.$store.state.checkoutTime;
    }
  }
};
</script>
